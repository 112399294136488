








import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import AppealAddingForm from "@/components/for-appeals/AppealAddingForm.vue";
import SimpleCard from "@/components/SimpleCard.vue";

@Component({
  components: {
    Page,
    SimpleCard,
    AppealAddingForm,
  },
})
class AppealsAdd extends Mixins(AppApiMixin) {
  [x: string]: any;
}

export default AppealsAdd

